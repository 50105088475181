<template>
  <div>
    <div class="inside">
      <p class="title">วันที่เริ่ม - วันที่สิ้นสุดการให้ประกัน</p>
      <p>
        {{ data.insuranceStart | formatDateDat }} ถึง
        {{ data.insuranceEnd | formatDateDat }}
      </p>
    </div>
  </div>
</template>
<script>
export default {
  props: ["data"],
};
</script>