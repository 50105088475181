<template>
  <div>
    <div class="inside">
      <p class="title">{{data.titleTwo}}</p>
      <div class="output ql-snow">
        <div class="ql-editor" v-html="data.insuranceCondition"></div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ["data"],
};
</script>