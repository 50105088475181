<template>
  <div>
    <el-col :span="24">
      <div class="box-table-detail">
        <p class="font-24 text-center mg-b-6">
          เกณฑ์ในการพิจารณาประกันการส่งออก
        </p>
        <TableDetailList :data="dataTable" page="export-insurance" />
      </div>
      <!-- <div class="card grade-size">
        <p>
          เกณฑ์ในการพิจารณา:
          <el-button class="btn-size cursor-none"
            >ขนาดธุรกิจ:
            <span v-if="data.insuranceSizeS">S </span>
            <span v-if="data.insuranceSizeM">M </span>
            <span v-if="data.insuranceSizeM && data.insuranceSizeL">, </span>
            <span v-if="data.insuranceSizeL">L </span>
          </el-button>
          <el-button class="btn-size cursor-none"
            >วงเงินค้าขายต่อ Shipment:
             <span v-if="data.shipmentMin == 0 && data.shipmentMax == 0"
              >ไม่ใช้เงื่อนไขวงเงินค้าขายต่อ Shipment</span
            >
            <span v-else-if="data.shipmentMin == 0"
              >ไม่เกิน 5 แสนบาท</span
            >
            <span v-else>เกิน 5 แสนบาท</span>
          </el-button>
          <el-button class="btn-size cursor-none"
            >มูลค่าการส่งออก:
            <span v-if="data.exportValueMin == 0 && data.exportValueMax == 0"
              >ไม่ใช้เงื่อนไขมูลค่าการส่งออก</span
            >
            <span v-else-if="data.exportValueMin == 0"
              >ไม่เกิน 200 ล้านบาท</span
            >
            <span v-else>ไม่เกิน 200 ล้านบาท</span>
          </el-button>
          <el-button class="btn-size cursor-none" v-if="data.term == 0">
            เทอมการชำระเงิน: 
            ไม่ใช้เงื่อนไขเทอมการชำระเงิน
          </el-button>
          <el-button class="btn-size cursor-none" v-else
            >เทอมการชำระเงิน:
            <span>ไม่เกิน {{ data.term }} วัน</span>
          </el-button>
        </p>
      </div> -->
    </el-col>
    <el-col :span="24">
      <el-row :gutter="30" class="custom-flex">
        <el-col :span="24" :sm="8" :md="6" class="mg-t-6 text-center">
          <img
            v-if="data.image != ''"
            :src="imgUrlTERAK + data.image"
            alt=""
            @click="$emit('morePicture', imgUrlTERAK + data.image)"
            class="imgCredit cursor-pointer"
          />
          <img
            v-else
            :src="require(`@/assets/image-default.jpg`)"
            alt=""
            class="imgCredit"
          />
        </el-col>
        <el-col :span="24" :sm="16" :md="18" class="mg-t-6">
          <el-tabs type="card" class="tab-card-sm">
            <el-tab-pane>
              <span slot="label">
                <p>วันที่เริ่ม - วันที่สิ้นสุด</p>
                <p>การให้ประกัน</p></span
              >
              <Tab1 :data="data" />
            </el-tab-pane>
            <el-tab-pane label="2" v-if="data.statusOne">
              <span slot="label">
                <p>{{ data.titleOne }}</p>
              </span>
              <Tab2 :data="data" />
            </el-tab-pane>
            <el-tab-pane label="3" v-if="data.statusTwo">
              <span slot="label">
                <p>{{ data.titleTwo }}</p>
              </span>
              <Tab3 :data="data" />
            </el-tab-pane>
            <el-tab-pane label="4" v-if="data.statusThree">
              <span slot="label">
                <p>{{ data.titleThree }}</p>
              </span>
              <Tab4 :data="data" />
            </el-tab-pane>
            <el-tab-pane label="5" v-if="data.statusFour">
              <span slot="label">
                <p>{{ data.titleFour }}</p>
              </span>
              <Tab5 :data="data" />
            </el-tab-pane>
            <el-tab-pane label="6" v-if="data.statusFive">
              <span slot="label">
                <p>{{ data.titleFive }}</p>
              </span>
              <Tab6 :data="data" />
            </el-tab-pane>
            <el-tab-pane label="7" v-if="data.statusSix">
              <span slot="label">
                <p>{{ data.titleSix }}</p>
              </span>
              <Tab7 :data="data" />
            </el-tab-pane>
            <el-tab-pane label="8" v-if="data.statusSeven">
              <span slot="label">
                <p>{{ data.titleSeven }}</p>
              </span>
              <Tab8 :data="data" />
            </el-tab-pane>
            <el-tab-pane label="9" v-if="data.statusEight">
              <span slot="label">
                <p>{{ data.titleEight }}</p>
              </span>
              <Tab9 :data="data" />
            </el-tab-pane>
            <el-tab-pane label="10" v-if="data.statusNine">
              <span slot="label">
                <p>{{ data.titleNine }}</p>
              </span>
              <div class="inside">
                <p class="title">{{ data.titleNine }}</p>
                <div class="output ql-snow">
                  <div class="ql-editor" v-html="data.detailsNine"></div>
                </div>
              </div>
            </el-tab-pane>
          </el-tabs>
        </el-col>
      </el-row>
    </el-col>
  </div>
</template>
<script>
import Tab1 from "@/components/export/detail/tab/Tab1";
import Tab2 from "@/components/export/detail/tab/Tab2";
import Tab3 from "@/components/export/detail/tab/Tab3";
import Tab4 from "@/components/export/detail/tab/Tab4";
import Tab5 from "@/components/export/detail/tab/Tab5";
import Tab6 from "@/components/export/detail/tab/Tab6";
import Tab7 from "@/components/export/detail/tab/Tab7";
import Tab8 from "@/components/export/detail/tab/Tab8";
import Tab9 from "@/components/export/detail/tab/Tab9";
import TableDetailList from "@/components/table/TableDetailList";

export default {
  components: {
    Tab1,
    Tab2,
    Tab3,
    Tab4,
    Tab5,
    Tab6,
    Tab7,
    Tab8,
    Tab9,
    TableDetailList,
  },
  props: ["data"],

  mounted() {
    // this.data.trem =
    // let a =
    // console.log(this.data.term.sort())
    this.data.term.sort((a, b) => {
      let modifier = 1;
      if (a < b) return -1 * modifier;
      if (a > b) return 1 * modifier;
      return 0;
    });

    this.data.exportValue.sort((a, b) => {
      let modifier = 1;
      if (a < b) return -1 * modifier;
      if (a > b) return 1 * modifier;
      return 0;
    });
    
    this.data.shipment.sort((a, b) => {
      let modifier = 1;
      if (a < b) return -1 * modifier;
      if (a > b) return 1 * modifier;
      return 0;
    });
    this.dataTable.push(this.data);
    if (this.data.exportValue.length > 3) {
      this.dataTable.push({
        _id: 99999,
        term: [],
        shipment: [],
        exportValue: [],
      });
    }
  },
  data() {
    return {
      dataTable: [],
      imgUrlTERAK: process.env.VUE_APP_BASE_URL_TERAK_IMAGE,
    };
  },
};
</script>